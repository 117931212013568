! function(e, t) {
    "function" == typeof define && define.amd ? define("ev-emitter/ev-emitter", t) : "object" == typeof module && module.exports ? module.exports = t() : e.EvEmitter = t()
}("undefined" != typeof window ? window : this, function() {
    function e() {}
    var t = e.prototype;
    return t.on = function(e, t) {
        if (e && t) {
            var i = this._events = this._events || {},
                s = i[e] = i[e] || [];
            return -1 == s.indexOf(t) && s.push(t), this
        }
    }, t.once = function(e, t) {
        if (e && t) {
            this.on(e, t);
            var i = this._onceEvents = this._onceEvents || {};
            return (i[e] = i[e] || {})[t] = !0, this
        }
    }, t.off = function(e, t) {
        var i = this._events && this._events[e];
        if (i && i.length) {
            var s = i.indexOf(t);
            return -1 != s && i.splice(s, 1), this
        }
    }, t.emitEvent = function(e, t) {
        var i = this._events && this._events[e];
        if (i && i.length) {
            var s = 0,
                a = i[s];
            t = t || [];
            for (var n = this._onceEvents && this._onceEvents[e]; a;) {
                var o = n && n[a];
                o && (this.off(e, a), delete n[a]), a.apply(this, t), a = i[s += o ? 0 : 1]
            }
            return this
        }
    }, e
}),
    function(l, i, a, r) {
        function d(e, t) {
            this.settings = null, this.options = l.extend({}, d.Defaults, t), this.$element = l(e), this._handlers = {}, this._plugins = {}, this._supress = {}, this._current = null, this._speed = null, this._coordinates = [], this._breakpoint = null, this._width = null, this._items = [], this._clones = [], this._mergers = [], this._widths = [], this._invalidated = {}, this._pipe = [], this._drag = {
                time: null,
                target: null,
                pointer: null,
                stage: {
                    start: null,
                    current: null
                },
                direction: null
            }, this._states = {
                current: {},
                tags: {
                    initializing: ["busy"],
                    animating: ["busy"],
                    dragging: ["interacting"]
                }
            }, l.each(["onResize", "onThrottledResize"], l.proxy(function(e, t) {
                this._handlers[t] = l.proxy(this[t], this)
            }, this)), l.each(d.Plugins, l.proxy(function(e, t) {
                this._plugins[e.charAt(0).toLowerCase() + e.slice(1)] = new t(this)
            }, this)), l.each(d.Workers, l.proxy(function(e, t) {
                this._pipe.push({
                    filter: t.filter,
                    run: l.proxy(t.run, this)
                })
            }, this)), this.setup(), this.initialize()
        }
        d.Defaults = {
            items: 3,
            loop: !1,
            center: !1,
            rewind: !1,
            checkVisibility: !0,
            mouseDrag: !0,
            touchDrag: !0,
            pullDrag: !0,
            freeDrag: !1,
            margin: 0,
            stagePadding: 0,
            merge: !1,
            mergeFit: !0,
            autoWidth: !1,
            startPosition: 0,
            rtl: !1,
            smartSpeed: 250,
            fluidSpeed: !1,
            dragEndSpeed: !1,
            responsive: {},
            responsiveRefreshRate: 200,
            responsiveBaseElement: i,
            fallbackEasing: "swing",
            slideTransition: "",
            info: !1,
            nestedItemSelector: !1,
            itemElement: "div",
            stageElement: "div",
            refreshClass: "owl-refresh",
            loadedClass: "owl-loaded",
            loadingClass: "owl-loading",
            rtlClass: "owl-rtl",
            responsiveClass: "owl-responsive",
            dragClass: "owl-drag",
            itemClass: "owl-item",
            stageClass: "owl-stage",
            stageOuterClass: "owl-stage-outer",
            grabClass: "owl-grab"
        }, d.Width = {
            Default: "default",
            Inner: "inner",
            Outer: "outer"
        }, d.Type = {
            Event: "event",
            State: "state"
        }, d.Plugins = {}, d.Workers = [{
            filter: ["width", "settings"],
            run: function() {
                this._width = this.$element.width()
            }
        }, {
            filter: ["width", "items", "settings"],
            run: function(e) {
                e.current = this._items && this._items[this.relative(this._current)]
            }
        }, {
            filter: ["items", "settings"],
            run: function() {
                this.$stage.children(".cloned").remove()
            }
        }, {
            filter: ["width", "items", "settings"],
            run: function(e) {
                var t = this.settings.margin || "",
                    i = !this.settings.autoWidth,
                    s = this.settings.rtl,
                    a = {
                        width: "auto",
                        "margin-left": s ? t : "",
                        "margin-right": s ? "" : t
                    };
                !i && this.$stage.children().css(a), e.css = a
            }
        }, {
            filter: ["width", "items", "settings"],
            run: function(e) {
                var t = (this.width() / this.settings.items).toFixed(3) - this.settings.margin,
                    i = null,
                    s = this._items.length,
                    a = !this.settings.autoWidth,
                    n = [];
                for (e.items = {
                        merge: !1,
                        width: t
                    }; s--;) i = this._mergers[s], i = this.settings.mergeFit && Math.min(i, this.settings.items) || i, e.items.merge = 1 < i || e.items.merge, n[s] = a ? t * i : this._items[s].width();
                this._widths = n
            }
        }, {
            filter: ["width", "items", "settings"],
            run: function() {
                for (var e = this.settings.rtl ? 1 : -1, t = this._clones.length + this._items.length, i = -1, s = 0, a = 0, n = []; ++i < t;) s = n[i - 1] || 0, a = this._widths[this.relative(i)] + this.settings.margin, n.push(s + a * e);
                this._coordinates = n
            }
        }, {
            filter: ["width", "items", "settings"],
            run: function() {
                var e = this.settings.stagePadding,
                    t = this._coordinates,
                    i = {
                        width: Math.ceil(Math.abs(t[t.length - 1])) + 2 * e,
                        "padding-left": e || "",
                        "padding-right": e || ""
                    };
                this.$stage.css(i)
            }
        }, {
            filter: ["width", "items", "settings"],
            run: function(e) {
                var t = this._coordinates.length,
                    i = !this.settings.autoWidth,
                    s = this.$stage.children();
                if (i && e.items.merge)
                    for (; t--;) e.css.width = this._widths[this.relative(t)], s.eq(t).css(e.css);
                else i && (e.css.width = e.items.width, s.css(e.css))
            }
        }, {
            filter: ["items"],
            run: function() {
                this._coordinates.length < 1 && this.$stage.removeAttr("style")
            }
        }, {
            filter: ["width", "items", "settings"],
            run: function(e) {
                e.current = e.current ? this.$stage.children().index(e.current) : 0, e.current = Math.max(this.minimum(), Math.min(this.maximum(), e.current)), this.reset(e.current)
            }
        }, {
            filter: ["position"],
            run: function() {
                this.animate(this.coordinates(this._current))
            }
        }, {
            filter: ["width", "position", "items", "settings"],
            run: function() {
                var e, t, i, s, a = this.settings.rtl ? 1 : -1,
                    n = 2 * this.settings.stagePadding,
                    o = this.coordinates(this.current()) + n,
                    r = o + this.width() * a,
                    l = [];
                for (i = 0, s = this._coordinates.length; i < s; i++) e = this._coordinates[i - 1] || 0, t = Math.abs(this._coordinates[i]) + n * a, (this.op(e, "<=", o) && this.op(e, ">", r) || this.op(t, "<", o) && this.op(t, ">", r)) && l.push(i);
                this.$stage.children(".active").removeClass("active"), this.$stage.children(":eq(" + l.join("), :eq(") + ")").addClass("active"), this.$stage.children(".center").removeClass("center"), this.settings.center && this.$stage.children().eq(this.current()).addClass("center")
            }
        }], d.prototype.initializeStage = function() {
            this.$stage = this.$element.find("." + this.settings.stageClass), this.$stage.length || (this.$element.addClass(this.options.loadingClass), this.$stage = l("<" + this.settings.stageElement + ">", {
                class: this.settings.stageClass
            }).wrap(l("<div/>", {
                class: this.settings.stageOuterClass
            })), this.$element.append(this.$stage.parent()))
        }, d.prototype.initializeItems = function() {
            var e = this.$element.find(".owl-item");
            if (e.length) return this._items = e.get().map(function(e) {
                return l(e)
            }), this._mergers = this._items.map(function() {
                return 1
            }), void this.refresh();
            this.replace(this.$element.children().not(this.$stage.parent())), this.isVisible() ? this.refresh() : this.invalidate("width"), this.$element.removeClass(this.options.loadingClass).addClass(this.options.loadedClass)
        }, d.prototype.initialize = function() {
            var e, t, i;
            (this.enter("initializing"), this.trigger("initialize"), this.$element.toggleClass(this.settings.rtlClass, this.settings.rtl), this.settings.autoWidth && !this.is("pre-loading")) && (e = this.$element.find("img"), t = this.settings.nestedItemSelector ? "." + this.settings.nestedItemSelector : r, i = this.$element.children(t).width(), e.length && i <= 0 && this.preloadAutoWidthImages(e));
            this.initializeStage(), this.initializeItems(), this.registerEventHandlers(), this.leave("initializing"), this.trigger("initialized")
        }, d.prototype.isVisible = function() {
            return !this.settings.checkVisibility || this.$element.is(":visible")
        }, d.prototype.setup = function() {
            var t = this.viewport(),
                e = this.options.responsive,
                i = -1,
                s = null;
            e ? (l.each(e, function(e) {
                e <= t && i < e && (i = Number(e))
            }), "function" == typeof(s = l.extend({}, this.options, e[i])).stagePadding && (s.stagePadding = s.stagePadding()), delete s.responsive, s.responsiveClass && this.$element.attr("class", this.$element.attr("class").replace(new RegExp("(" + this.options.responsiveClass + "-)\\S+\\s", "g"), "$1" + i))) : s = l.extend({}, this.options), this.trigger("change", {
                property: {
                    name: "settings",
                    value: s
                }
            }), this._breakpoint = i, this.settings = s, this.invalidate("settings"), this.trigger("changed", {
                property: {
                    name: "settings",
                    value: this.settings
                }
            })
        }, d.prototype.optionsLogic = function() {
            this.settings.autoWidth && (this.settings.stagePadding = !1, this.settings.merge = !1)
        }, d.prototype.prepare = function(e) {
            var t = this.trigger("prepare", {
                content: e
            });
            return t.data || (t.data = l("<" + this.settings.itemElement + "/>").addClass(this.options.itemClass).append(e)), this.trigger("prepared", {
                content: t.data
            }), t.data
        }, d.prototype.update = function() {
            for (var e = 0, t = this._pipe.length, i = l.proxy(function(e) {
                    return this[e]
                }, this._invalidated), s = {}; e < t;)(this._invalidated.all || 0 < l.grep(this._pipe[e].filter, i).length) && this._pipe[e].run(s), e++;
            this._invalidated = {}, !this.is("valid") && this.enter("valid")
        }, d.prototype.width = function(e) {
            switch (e = e || d.Width.Default) {
                case d.Width.Inner:
                case d.Width.Outer:
                    return this._width;
                default:
                    return this._width - 2 * this.settings.stagePadding + this.settings.margin
            }
        }, d.prototype.refresh = function() {
            this.enter("refreshing"), this.trigger("refresh"), this.setup(), this.optionsLogic(), this.$element.addClass(this.options.refreshClass), this.update(), this.$element.removeClass(this.options.refreshClass), this.leave("refreshing"), this.trigger("refreshed")
        }, d.prototype.onThrottledResize = function() {
            i.clearTimeout(this.resizeTimer), this.resizeTimer = i.setTimeout(this._handlers.onResize, this.settings.responsiveRefreshRate)
        }, d.prototype.onResize = function() {
            return !!this._items.length && this._width !== this.$element.width() && !!this.isVisible() && (this.enter("resizing"), this.trigger("resize").isDefaultPrevented() ? (this.leave("resizing"), !1) : (this.invalidate("width"), this.refresh(), this.leave("resizing"), void this.trigger("resized")))
        }, d.prototype.registerEventHandlers = function() {
            l.support.transition && this.$stage.on(l.support.transition.end + ".owl.core", l.proxy(this.onTransitionEnd, this)), !1 !== this.settings.responsive && this.on(i, "resize", this._handlers.onThrottledResize), this.settings.mouseDrag && (this.$element.addClass(this.options.dragClass), this.$stage.on("mousedown.owl.core", l.proxy(this.onDragStart, this)), this.$stage.on("dragstart.owl.core selectstart.owl.core", function() {
                return !1
            })), this.settings.touchDrag && (this.$stage.on("touchstart.owl.core", l.proxy(this.onDragStart, this)), this.$stage.on("touchcancel.owl.core", l.proxy(this.onDragEnd, this)))
        }, d.prototype.onDragStart = function(e) {
            var t = null;
            3 !== e.which && (l.support.transform ? t = {
                x: (t = this.$stage.css("transform").replace(/.*\(|\)| /g, "").split(","))[16 === t.length ? 12 : 4],
                y: t[16 === t.length ? 13 : 5]
            } : (t = this.$stage.position(), t = {
                x: this.settings.rtl ? t.left + this.$stage.width() - this.width() + this.settings.margin : t.left,
                y: t.top
            }), this.is("animating") && (l.support.transform ? this.animate(t.x) : this.$stage.stop(), this.invalidate("position")), this.$element.toggleClass(this.options.grabClass, "mousedown" === e.type), this.speed(0), this._drag.time = (new Date).getTime(), this._drag.target = l(e.target), this._drag.stage.start = t, this._drag.stage.current = t, this._drag.pointer = this.pointer(e), l(a).on("mouseup.owl.core touchend.owl.core", l.proxy(this.onDragEnd, this)), l(a).one("mousemove.owl.core touchmove.owl.core", l.proxy(function(e) {
                var t = this.difference(this._drag.pointer, this.pointer(e));
                l(a).on("mousemove.owl.core touchmove.owl.core", l.proxy(this.onDragMove, this)), Math.abs(t.x) < Math.abs(t.y) && this.is("valid") || (e.preventDefault(), this.enter("dragging"), this.trigger("drag"))
            }, this)))
        }, d.prototype.onDragMove = function(e) {
            var t = null,
                i = null,
                s = null,
                a = this.difference(this._drag.pointer, this.pointer(e)),
                n = this.difference(this._drag.stage.start, a);
            this.is("dragging") && (e.preventDefault(), this.settings.loop ? (t = this.coordinates(this.minimum()), i = this.coordinates(this.maximum() + 1) - t, n.x = ((n.x - t) % i + i) % i + t) : (t = this.settings.rtl ? this.coordinates(this.maximum()) : this.coordinates(this.minimum()), i = this.settings.rtl ? this.coordinates(this.minimum()) : this.coordinates(this.maximum()), s = this.settings.pullDrag ? -1 * a.x / 5 : 0, n.x = Math.max(Math.min(n.x, t + s), i + s)), this._drag.stage.current = n, this.animate(n.x))
        }, d.prototype.onDragEnd = function(e) {
            var t = this.difference(this._drag.pointer, this.pointer(e)),
                i = this._drag.stage.current,
                s = 0 < t.x ^ this.settings.rtl ? "left" : "right";
            l(a).off(".owl.core"), this.$element.removeClass(this.options.grabClass), (0 !== t.x && this.is("dragging") || !this.is("valid")) && (this.speed(this.settings.dragEndSpeed || this.settings.smartSpeed), this.current(this.closest(i.x, 0 !== t.x ? s : this._drag.direction)), this.invalidate("position"), this.update(), this._drag.direction = s, (3 < Math.abs(t.x) || 300 < (new Date).getTime() - this._drag.time) && this._drag.target.one("click.owl.core", function() {
                return !1
            })), this.is("dragging") && (this.leave("dragging"), this.trigger("dragged"))
        }, d.prototype.closest = function(i, s) {
            var a = -1,
                n = this.width(),
                o = this.coordinates();
            return this.settings.freeDrag || l.each(o, l.proxy(function(e, t) {
                return "left" === s && t - 30 < i && i < t + 30 ? a = e : "right" === s && t - n - 30 < i && i < t - n + 30 ? a = e + 1 : this.op(i, "<", t) && this.op(i, ">", o[e + 1] !== r ? o[e + 1] : t - n) && (a = "left" === s ? e + 1 : e), -1 === a
            }, this)), this.settings.loop || (this.op(i, ">", o[this.minimum()]) ? a = i = this.minimum() : this.op(i, "<", o[this.maximum()]) && (a = i = this.maximum())), a
        }, d.prototype.animate = function(e) {
            var t = 0 < this.speed();
            this.is("animating") && this.onTransitionEnd(), t && (this.enter("animating"), this.trigger("translate")), l.support.transform3d && l.support.transition ? this.$stage.css({
                transform: "translate3d(" + e + "px,0px,0px)",
                transition: this.speed() / 1e3 + "s" + (this.settings.slideTransition ? " " + this.settings.slideTransition : "")
            }) : t ? this.$stage.animate({
                left: e + "px"
            }, this.speed(), this.settings.fallbackEasing, l.proxy(this.onTransitionEnd, this)) : this.$stage.css({
                left: e + "px"
            })
        }, d.prototype.is = function(e) {
            return this._states.current[e] && 0 < this._states.current[e]
        }, d.prototype.current = function(e) {
            if (e === r) return this._current;
            if (0 === this._items.length) return r;
            if (e = this.normalize(e), this._current !== e) {
                var t = this.trigger("change", {
                    property: {
                        name: "position",
                        value: e
                    }
                });
                t.data !== r && (e = this.normalize(t.data)), this._current = e, this.invalidate("position"), this.trigger("changed", {
                    property: {
                        name: "position",
                        value: this._current
                    }
                })
            }
            return this._current
        }, d.prototype.invalidate = function(e) {
            return "string" === l.type(e) && (this._invalidated[e] = !0, this.is("valid") && this.leave("valid")), l.map(this._invalidated, function(e, t) {
                return t
            })
        }, d.prototype.reset = function(e) {
            (e = this.normalize(e)) !== r && (this._speed = 0, this._current = e, this.suppress(["translate", "translated"]), this.animate(this.coordinates(e)), this.release(["translate", "translated"]))
        }, d.prototype.normalize = function(e, t) {
            var i = this._items.length,
                s = t ? 0 : this._clones.length;
            return !this.isNumeric(e) || i < 1 ? e = r : (e < 0 || i + s <= e) && (e = ((e - s / 2) % i + i) % i + s / 2), e
        }, d.prototype.relative = function(e) {
            return e -= this._clones.length / 2, this.normalize(e, !0)
        }, d.prototype.maximum = function(e) {
            var t, i, s, a = this.settings,
                n = this._coordinates.length;
            if (a.loop) n = this._clones.length / 2 + this._items.length - 1;
            else if (a.autoWidth || a.merge) {
                if (t = this._items.length)
                    for (i = this._items[--t].width(), s = this.$element.width(); t-- && !((i += this._items[t].width() + this.settings.margin) > s););
                n = t + 1
            } else n = a.center ? this._items.length - 1 : this._items.length - a.items;
            return e && (n -= this._clones.length / 2), Math.max(n, 0)
        }, d.prototype.minimum = function(e) {
            return e ? 0 : this._clones.length / 2
        }, d.prototype.items = function(e) {
            return e === r ? this._items.slice() : (e = this.normalize(e, !0), this._items[e])
        }, d.prototype.mergers = function(e) {
            return e === r ? this._mergers.slice() : (e = this.normalize(e, !0), this._mergers[e])
        }, d.prototype.clones = function(i) {
            var t = this._clones.length / 2,
                s = t + this._items.length,
                a = function(e) {
                    return e % 2 == 0 ? s + e / 2 : t - (e + 1) / 2
                };
            return i === r ? l.map(this._clones, function(e, t) {
                return a(t)
            }) : l.map(this._clones, function(e, t) {
                return e === i ? a(t) : null
            })
        }, d.prototype.speed = function(e) {
            return e !== r && (this._speed = e), this._speed
        }, d.prototype.coordinates = function(e) {
            var t, i = 1,
                s = e - 1;
            return e === r ? l.map(this._coordinates, l.proxy(function(e, t) {
                return this.coordinates(t)
            }, this)) : (this.settings.center ? (this.settings.rtl && (i = -1, s = e + 1), t = this._coordinates[e], t += (this.width() - t + (this._coordinates[s] || 0)) / 2 * i) : t = this._coordinates[s] || 0, t = Math.ceil(t))
        }, d.prototype.duration = function(e, t, i) {
            return 0 === i ? 0 : Math.min(Math.max(Math.abs(t - e), 1), 6) * Math.abs(i || this.settings.smartSpeed)
        }, d.prototype.to = function(e, t) {
            var i = this.current(),
                s = null,
                a = e - this.relative(i),
                n = (0 < a) - (a < 0),
                o = this._items.length,
                r = this.minimum(),
                l = this.maximum();
            this.settings.loop ? (!this.settings.rewind && Math.abs(a) > o / 2 && (a += -1 * n * o), (s = (((e = i + a) - r) % o + o) % o + r) !== e && s - a <= l && 0 < s - a && (i = s - a, e = s, this.reset(i))) : this.settings.rewind ? e = (e % (l += 1) + l) % l : e = Math.max(r, Math.min(l, e)), this.speed(this.duration(i, e, t)), this.current(e), this.isVisible() && this.update()
        }, d.prototype.next = function(e) {
            e = e || !1, this.to(this.relative(this.current()) + 1, e)
        }, d.prototype.prev = function(e) {
            e = e || !1, this.to(this.relative(this.current()) - 1, e)
        }, d.prototype.onTransitionEnd = function(e) {
            if (e !== r && (e.stopPropagation(), (e.target || e.srcElement || e.originalTarget) !== this.$stage.get(0))) return !1;
            this.leave("animating"), this.trigger("translated")
        }, d.prototype.viewport = function() {
            var e;
            return this.options.responsiveBaseElement !== i ? e = l(this.options.responsiveBaseElement).width() : i.innerWidth ? e = i.innerWidth : a.documentElement && a.documentElement.clientWidth ? e = a.documentElement.clientWidth : console.warn("Can not detect viewport width."), e
        }, d.prototype.replace = function(e) {
            this.$stage.empty(), this._items = [], e && (e = e instanceof jQuery ? e : l(e)), this.settings.nestedItemSelector && (e = e.find("." + this.settings.nestedItemSelector)), e.filter(function() {
                return 1 === this.nodeType
            }).each(l.proxy(function(e, t) {
                t = this.prepare(t), this.$stage.append(t), this._items.push(t), this._mergers.push(1 * t.find("[data-merge]").addBack("[data-merge]").attr("data-merge") || 1)
            }, this)), this.reset(this.isNumeric(this.settings.startPosition) ? this.settings.startPosition : 0), this.invalidate("items")
        }, d.prototype.add = function(e, t) {
            var i = this.relative(this._current);
            t = t === r ? this._items.length : this.normalize(t, !0), e = e instanceof jQuery ? e : l(e), this.trigger("add", {
                content: e,
                position: t
            }), e = this.prepare(e), 0 === this._items.length || t === this._items.length ? (0 === this._items.length && this.$stage.append(e), 0 !== this._items.length && this._items[t - 1].after(e), this._items.push(e), this._mergers.push(1 * e.find("[data-merge]").addBack("[data-merge]").attr("data-merge") || 1)) : (this._items[t].before(e), this._items.splice(t, 0, e), this._mergers.splice(t, 0, 1 * e.find("[data-merge]").addBack("[data-merge]").attr("data-merge") || 1)), this._items[i] && this.reset(this._items[i].index()), this.invalidate("items"), this.trigger("added", {
                content: e,
                position: t
            })
        }, d.prototype.remove = function(e) {
            (e = this.normalize(e, !0)) !== r && (this.trigger("remove", {
                content: this._items[e],
                position: e
            }), this._items[e].remove(), this._items.splice(e, 1), this._mergers.splice(e, 1), this.invalidate("items"), this.trigger("removed", {
                content: null,
                position: e
            }))
        }, d.prototype.preloadAutoWidthImages = function(e) {
            e.each(l.proxy(function(e, t) {
                this.enter("pre-loading"), t = l(t), l(new Image).one("load", l.proxy(function(e) {
                    t.attr("src", e.target.src), t.css("opacity", 1), this.leave("pre-loading"), !this.is("pre-loading") && !this.is("initializing") && this.refresh()
                }, this)).attr("src", t.attr("src") || t.attr("data-src") || t.attr("data-src-retina"))
            }, this))
        }, d.prototype.destroy = function() {
            for (var e in this.$element.off(".owl.core"), this.$stage.off(".owl.core"), l(a).off(".owl.core"), !1 !== this.settings.responsive && (i.clearTimeout(this.resizeTimer), this.off(i, "resize", this._handlers.onThrottledResize)), this._plugins) this._plugins[e].destroy();
            this.$stage.children(".cloned").remove(), this.$stage.unwrap(), this.$stage.children().contents().unwrap(), this.$stage.children().unwrap(), this.$stage.remove(), this.$element.removeClass(this.options.refreshClass).removeClass(this.options.loadingClass).removeClass(this.options.loadedClass).removeClass(this.options.rtlClass).removeClass(this.options.dragClass).removeClass(this.options.grabClass).attr("class", this.$element.attr("class").replace(new RegExp(this.options.responsiveClass + "-\\S+\\s", "g"), "")).removeData("owl.carousel")
        }, d.prototype.op = function(e, t, i) {
            var s = this.settings.rtl;
            switch (t) {
                case "<":
                    return s ? i < e : e < i;
                case ">":
                    return s ? e < i : i < e;
                case ">=":
                    return s ? e <= i : i <= e;
                case "<=":
                    return s ? i <= e : e <= i
            }
        }, d.prototype.on = function(e, t, i, s) {
            e.addEventListener ? e.addEventListener(t, i, s) : e.attachEvent && e.attachEvent("on" + t, i)
        }, d.prototype.off = function(e, t, i, s) {
            e.removeEventListener ? e.removeEventListener(t, i, s) : e.detachEvent && e.detachEvent("on" + t, i)
        }, d.prototype.trigger = function(e, t, i, s, a) {
            var n = {
                    item: {
                        count: this._items.length,
                        index: this.current()
                    }
                },
                o = l.camelCase(l.grep(["on", e, i], function(e) {
                    return e
                }).join("-").toLowerCase()),
                r = l.Event([e, "owl", i || "carousel"].join(".").toLowerCase(), l.extend({
                    relatedTarget: this
                }, n, t));
            return this._supress[e] || (l.each(this._plugins, function(e, t) {
                t.onTrigger && t.onTrigger(r)
            }), this.register({
                type: d.Type.Event,
                name: e
            }), this.$element.trigger(r), this.settings && "function" == typeof this.settings[o] && this.settings[o].call(this, r)), r
        }, d.prototype.enter = function(e) {
            l.each([e].concat(this._states.tags[e] || []), l.proxy(function(e, t) {
                this._states.current[t] === r && (this._states.current[t] = 0), this._states.current[t]++
            }, this))
        }, d.prototype.leave = function(e) {
            l.each([e].concat(this._states.tags[e] || []), l.proxy(function(e, t) {
                this._states.current[t]--
            }, this))
        }, d.prototype.register = function(i) {
            if (i.type === d.Type.Event) {
                if (l.event.special[i.name] || (l.event.special[i.name] = {}), !l.event.special[i.name].owl) {
                    var t = l.event.special[i.name]._default;
                    l.event.special[i.name]._default = function(e) {
                        return !t || !t.apply || e.namespace && -1 !== e.namespace.indexOf("owl") ? e.namespace && -1 < e.namespace.indexOf("owl") : t.apply(this, arguments)
                    }, l.event.special[i.name].owl = !0
                }
            } else i.type === d.Type.State && (this._states.tags[i.name] ? this._states.tags[i.name] = this._states.tags[i.name].concat(i.tags) : this._states.tags[i.name] = i.tags, this._states.tags[i.name] = l.grep(this._states.tags[i.name], l.proxy(function(e, t) {
                return l.inArray(e, this._states.tags[i.name]) === t
            }, this)))
        }, d.prototype.suppress = function(e) {
            l.each(e, l.proxy(function(e, t) {
                this._supress[t] = !0
            }, this))
        }, d.prototype.release = function(e) {
            l.each(e, l.proxy(function(e, t) {
                delete this._supress[t]
            }, this))
        }, d.prototype.pointer = function(e) {
            var t = {
                x: null,
                y: null
            };
            return (e = (e = e.originalEvent || e || i.event).touches && e.touches.length ? e.touches[0] : e.changedTouches && e.changedTouches.length ? e.changedTouches[0] : e).pageX ? (t.x = e.pageX, t.y = e.pageY) : (t.x = e.clientX, t.y = e.clientY), t
        }, d.prototype.isNumeric = function(e) {
            return !isNaN(parseFloat(e))
        }, d.prototype.difference = function(e, t) {
            return {
                x: e.x - t.x,
                y: e.y - t.y
            }
        }, l.fn.owlCarousel = function(t) {
            var s = Array.prototype.slice.call(arguments, 1);
            return this.each(function() {
                var e = l(this),
                    i = e.data("owl.carousel");
                i || (i = new d(this, "object" == typeof t && t), e.data("owl.carousel", i), l.each(["next", "prev", "to", "destroy", "refresh", "replace", "add", "remove"], function(e, t) {
                    i.register({
                        type: d.Type.Event,
                        name: t
                    }), i.$element.on(t + ".owl.carousel.core", l.proxy(function(e) {
                        e.namespace && e.relatedTarget !== this && (this.suppress([t]), i[t].apply(this, [].slice.call(arguments, 1)), this.release([t]))
                    }, i))
                })), "string" == typeof t && "_" !== t.charAt(0) && i[t].apply(i, s)
            })
        }, l.fn.owlCarousel.Constructor = d
    }(window.Zepto || window.jQuery, window, document),
    function(t, i) {
        "function" == typeof define && define.amd ? define(["jquery"], function(e) {
            return i(t, e)
        }) : "object" == typeof exports ? i(t, require("jquery")) : i(t, t.jQuery || t.Zepto)
    }(this, function(e, o) {
        "use strict";
        var t, s, i, n = "remodal",
            a = e.REMODAL_GLOBALS && e.REMODAL_GLOBALS.NAMESPACE || n,
            r = o.map(["animationstart", "webkitAnimationStart", "MSAnimationStart", "oAnimationStart"], function(e) {
                return e + "." + a
            }).join(" "),
            l = o.map(["animationend", "webkitAnimationEnd", "MSAnimationEnd", "oAnimationEnd"], function(e) {
                return e + "." + a
            }).join(" "),
            d = o.extend({
                hashTracking: !0,
                closeOnConfirm: !0,
                closeOnCancel: !0,
                closeOnEscape: !0,
                closeOnOutsideClick: !0,
                modifier: "",
                appendTo: null
            }, e.REMODAL_GLOBALS && e.REMODAL_GLOBALS.DEFAULTS),
            c = {
                CLOSING: "closing",
                CLOSED: "closed",
                OPENING: "opening",
                OPENED: "opened"
            },
            h = "confirmation",
            p = "cancellation",
            u = void 0 !== (t = document.createElement("div").style).animationName || void 0 !== t.WebkitAnimationName || void 0 !== t.MozAnimationName || void 0 !== t.msAnimationName || void 0 !== t.OAnimationName,
            f = /iPad|iPhone|iPod/.test(navigator.platform);

        function m(e) {
            if (u && "none" === e.css("animation-name") && "none" === e.css("-webkit-animation-name") && "none" === e.css("-moz-animation-name") && "none" === e.css("-o-animation-name") && "none" === e.css("-ms-animation-name")) return 0;
            var t, i, s, a, n = e.css("animation-duration") || e.css("-webkit-animation-duration") || e.css("-moz-animation-duration") || e.css("-o-animation-duration") || e.css("-ms-animation-duration") || "0s",
                o = e.css("animation-delay") || e.css("-webkit-animation-delay") || e.css("-moz-animation-delay") || e.css("-o-animation-delay") || e.css("-ms-animation-delay") || "0s",
                r = e.css("animation-iteration-count") || e.css("-webkit-animation-iteration-count") || e.css("-moz-animation-iteration-count") || e.css("-o-animation-iteration-count") || e.css("-ms-animation-iteration-count") || "1";
            for (n = n.split(", "), o = o.split(", "), r = r.split(", "), a = 0, i = n.length, t = Number.NEGATIVE_INFINITY; a < i; a++) t < (s = parseFloat(n[a]) * parseInt(r[a], 10) + parseFloat(o[a])) && (t = s);
            return t
        }

        function v() {
            if (o(document).height() <= o(window).height()) return 0;
            var e, t, i = document.createElement("div"),
                s = document.createElement("div");
            return i.style.visibility = "hidden", i.style.width = "100px", document.body.appendChild(i), e = i.offsetWidth, i.style.overflow = "scroll", s.style.width = "100%", i.appendChild(s), t = s.offsetWidth, i.parentNode.removeChild(i), e - t
        }
    });